import React from 'react';
import { Link, graphql } from 'gatsby';
import Layout from '../components/layout';

const Tags = ({ pageContext, data }) => {
  const { tag } = pageContext;
  const bizcard = data.bizcards.edges[0].node
  const { edges, totalCount } = data.allMarkdownRemark;
  const tagHeader = `${totalCount} post${
    totalCount === 1 ? '' : 's'
  } tagged with "${tag}"`;
  return (
    <Layout bizcard={bizcard}>
      <div className="blog-tags">
        <h1>{tagHeader}</h1>
        <ul className="tag-list">
          {edges.map(({ node }) => {
            const { title, date } = node.frontmatter;
            const { slug } = node.fields;
            return (
              <span key={slug}>
                <Link to={slug}>
                  {title}
                </Link>
                <small><span> | {date}</span></small>
              </span>
            );
          })}
        </ul>
        <span>
          <Link to="/tags">← All tags</Link>
        </span>
      </div>
    </Layout>
  );
};

export default Tags

export const pageQuery = graphql`
  query($tag: String) {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }

    bizcards: allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "bizcard-page"}}}) {
      edges {
        node {
          id
          fields {
            slug
          }
          html
          frontmatter {
            title
            date
            description
            featuredpost
            templateKey
            name
            company
            phoneOffice
            phoneMobile
            email
            website
            address
            facebook
            twitter
            linkedin
            instagram
            youtube
            links {
              linkName
              linkUrl
              icon {
                childImageSharp {
                  fluid(maxWidth: 300) {
                    aspectRatio
                    base64
                    sizes
                    src
                    srcSet
                  }
                }
              }
            }
            img {
              childImageSharp {
                fluid(maxWidth: 900) {
                  aspectRatio
                  base64
                  sizes
                  src
                  srcSet
                }
              }
            }
            logo {
              childImageSharp {
                fluid(maxWidth: 800) {
                  aspectRatio
                  base64
                  sizes
                  src
                  srcSet
                }
              }
            }
          }
        }
      }
    }
  }
`;
